import React, { useState, useEffect ,useContext ,useRef} from 'react';
import { Menu, Grid, Form, Button, Input, Dropdown, Tab,Message } from "semantic-ui-react";
import DataLayerModal from './DataLayerModal';
import styles from "./DataConnections.module.css";
import DatabaseListing from './DatabaseListing';
import { DataProvider } from './DataProvider';
import axios from 'axios'; // For making API requests
import dataconnection from '../../../api/DataConnection/dataconnection';
import ConnectionList from './ConnectionList';
import { DataContext } from './DataProvider';
const authOptions = [
    { key: "none", value: "none", text: "None" },
    { key: "bearer", value: "bearer", text: "Bearer Token" },
    { key: "basic", value: "basic", text: "Basic Auth" },
	{ key: "apikey", value: "apikey", text: "Api Key" },
];

const Dataconnectionwrapper = () => {
    const {
        toolapiendpoint,
        loadToolApiendpoint,
		connections, 
		updateConeection
    } = useContext(DataContext);
	const topRef = useRef(null);  // Create a ref for the scroll target
	// add state for time in swc
	const [timeInSWC, setTimeInSWC] = useState("");
	const [showConnectionform, setShowConnectionform] = useState(true);
    const [activeTab, setActiveTab] = useState("params");
    const [modalStatus, setmodalStatus] = useState(false);
    const [databases, setDatabases] = useState([]);
    const [selectedAuthType, setSelectedAuthType] = useState("none");
	const [selectedBodyType, setSelectedBodyType] = useState("formdata");
    const [initFormdata, setinitFormdata] = useState({});
	const [keyValuePairs, setKeyValuePairs] = useState([{ key: "", value: "" }]);
	const [currentKey, setCurrentKey] = useState("");
	const [currentValue, setCurrentValue] = useState("");
	const [place, setPlace] = useState("");
	const [operation, setOperation] = useState("add");
	const [rawBody, setRawBody] = useState("{}");
	const [responseOutput, setResponseOutput] = useState(null); 
	const [method, setMethod] = useState("get"); // HTTP method
	const [endpointUrl, setEndpointUrl] = useState("");
	const [headers, setHeaders] = useState([{ key: "", value: "" }]); // Dynamic headers
	const [paramskeyValuePairs, setParamKeyValuePairs] = useState([{ key: "", value: "" }]);
	//states for mapping response in setting 
	const [databaseColumns,setDatabaseColumns] = useState([]); // Example database columns
	const [availableKeys, setAvailableKeys] = useState(["key1", "key2", "key3"]); // Keys from the response tab
	// Initialize mappings with default empty values for each column
	const [mappings, setMappings] = useState({});
	// Usage example
	const [selectedDatabase, setSelectedDatabase] = useState(null);
	const [errorMessage, setErrorMessage] = useState(""); // Error message state
	const [errorMessageType, setErrorMessageType] = useState("warning"); // Error message state
	const [dbloaderSatus,setDbloaderStatus] =  useState(true);
	const [conloaderSatus,setConloaderStatus] =  useState(true);

	const [authConfig, setAuthConfig] = useState({
		id:null,
		endpoint_name : "",
        type: "none",
        username: "",
        password: "",
        token: "",
        apiKey: {
            key: "",
            value: "",
            place: "header",
        },
    });

	const availableDatabases = () => {
		let listofdb = databases ?? [];
		listofdb = listofdb.map((db) => {
			let colm = JSON.parse(db.columns);
			return {
				key: db.id,
				text: db.name,
				value: db.tablename,
				columns: colm.map((col) => {
					return col.name;
				}), // Columns for Database 1
			}
		})
		return listofdb;
	}

	const setDbloaderStatusp = ( status ) => {
		setDbloaderStatus(status);
	}

	// render database selection
	const renderDatabaseSelection = (selectedDatabase, handleDatabaseChange) => (
		<div
		style={{
			display: "flex",
			alignItems: "center",
			marginBottom: "10px",
		}}>
		  <label style={{ marginRight: "10px", fontWeight: "bold", minWidth: "120px" }}>
			Select Database:
		  </label>
		  <Dropdown
			placeholder="Select Database"
			selection
			options={availableDatabases()}
			value={selectedDatabase}
			onChange={(e,{value}) => handleDatabaseChange({value})} // Handle database selection
			style={{ flex: 1 }}
		  />
		</div>
	);

	// Function to handle the change when a database is selected
	const handleDatabaseChange = ({value}) => {
		const selectedDb = availableDatabases().find((db) => db.value === value);
		setSelectedDatabase(value); // Set the selected database in the state
		// Set the columns of the selected database
		setDatabaseColumns(selectedDb ? selectedDb.columns : []); 
		// reseting mapping 
		setMappings({})

		// Extract and set available keys
		const extractedKeys = extractKeysFromResponse(responseOutput);
		setAvailableKeys(extractedKeys);
	}; 

	//function for mappping table colum and response keys
	const handleMapping = (column, selectedKey) => {
		setMappings((prevMappings) => {
		  // Get the previous selected key
		  const previousKey = prevMappings[column];
		  // Update mappings with the new selected key
		  const updatedMappings = { ...prevMappings, [column]: selectedKey };
		  // If there was a previous key, add it back to availableKeys
		  if (previousKey && previousKey !== selectedKey) {
			setAvailableKeys((prevKeys) => [...prevKeys, previousKey]);
		  }
		  return updatedMappings;
		});
		// Remove the selected key from availableKeys
		setAvailableKeys((prevKeys) => prevKeys.filter((key) => key !== selectedKey));
	};


	//extract keys from the response json
	const extractKeysFromResponse = (responseData) => {
		const keys = new Set();
	
		const extractKeys = (data, parentKey = "") => {
			if (typeof data === "object" && data !== null) {
				Object.keys(data).forEach((key) => {
					const fullKey = parentKey ? `${parentKey}.${key}` : key;
					keys.add(fullKey);
					extractKeys(data[key], fullKey); // Recursively extract nested keys
				});
			}
		};
	
		extractKeys(responseData);
		return Array.from(keys); // Convert Set to Array
	};

	//resdering setting mapping stuffs
	const renderSettingsItems = (databaseColumns, availableKeys, handleMapping, mappings) => {
		return (
			<div style={{ marginTop: "20px" }}>
				{/* adding a input feild and label for time in swc */}
				<div style={{ marginTop: "10px" }}>
					<label>Time in SWC</label>
					<Input
						placeholder="Time in SWC"
						value={timeInSWC}
						onChange={(e) => setTimeInSWC(e.target.value)}
					/>
				</div> 
				{/* add a dropdown selection having option Add.Replace */}
				<div style={{ marginTop: "20px" }}>
					<label style={{ marginRight: "10px" }}>Operation </label>
					<Dropdown
						placeholder="Select Operation"
						selection
						options={[
							{ key: "add", value: "add", text: "Add" },
							{ key: "replace", value: "replace", text: "Replace" },
						]}
						value={operation}
						onChange={(e, { value }) => setOperation(value)}
					/>
				</div>
				<h4>Map Database Columns to Response Keys</h4>
				{Array.from(databaseColumns).map((column, index) => (
					<div
						key={index}
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "10px",
						}}
					>
						<label
							style={{
								marginRight: "10px",
								fontWeight: "bold",
								minWidth: "100px",
							}}
						>
							{column}
						</label>
						<Dropdown
							placeholder={mappings[column] ?? ""}
							selection
							options={availableKeys.map((key) => ({
								key,
								text: key,
								value: key,
							}))}
							value={mappings[column] ?? ""}
							onChange={(e, { value }) => handleMapping(column, value)}
							style={{ flex: 1 }}
						/>
					</div>
				))}
			</div>
		);
	};
	

	// Updated to store headers as an array of objects
  
    useEffect(() => {
		let listofdb = connections ?? [];		
        setActiveTab("params");
        setDatabases(listofdb);
    }, [modalStatus,connections,dbloaderSatus]);



	const handleAuthTypeChange = (e, { value }) => {
		setSelectedAuthType(value)
		setAuthConfig((prev) => ({ ...prev, type: value }));
	};

	// Handle changes for API Key
	const handleKeyChange = (e) => {
		const newKey = e.target.value;
		setAuthConfig((prev) => ({
			...prev,
			apiKey: { ...prev.apiKey, key: newKey },
		}));
	};

	const handleValueChange = (e) => {
		const value = e.target.value;
		setAuthConfig((prev) => ({
			...prev,
			apiKey: { ...prev.apiKey, value: value },
		}));
		setCurrentValue(e.target.value);
		
	};

	const handlePlaceChange = (e, { value }) => {
		const place = e.target.value;
		setAuthConfig((prev) => ({
			...prev,
			apiKey: { ...prev.apiKey, type: place },
		}));
	};

	// Handle changes for username
    const handleUsernameChange = (e) => {
        const value = e.target?.value;
        setAuthConfig((prev) => ({
            ...prev,
            username: value,
        }));
    };

    // Handle changes for password
    const handlePasswordChange = (e) => {
        const value = e.target?.value;
        setAuthConfig((prev) => ({
            ...prev,
            password: value,
        }));
    };

	// Handle changes for Bearer Token
	const handleTokenChange = (e) => {
        const value = e.target?.value;
        setAuthConfig((prev) => ({
            ...prev,
            token: value,
        }));
	};

	//create a function which can set setErrorMessage value and set timeout to clear value after few seconds
	const setErrorMessageWithTimeout = (message, type = "warning") => {
		setErrorMessageType(type);
		setErrorMessage(message);
		// Scroll to top smoothly
		// Scroll to the referenced element
		if (topRef.current) {
			topRef.current.scrollIntoView({ behavior: "smooth" });
		}
		setTimeout(() => {
			setErrorMessage("");
		}, 5000);
	};

    //reset form for connection
    const resetCOnnectform = () => {
		setEndpointUrl("");
		setMappings([]);
		setSelectedDatabase("");
		setMethod("get");
		setHeaders([]);
		setSelectedAuthType("none");
        setRawBody("{}")
        setSelectedBodyType("formdata")
		setAuthConfig({
            id:null,
            endpoint_name : "",
            type: "none",
            username: "",
            password: "",
            token: "",
            apiKey: {
                key: "",
                value: "",
                place: "header",
            },
		});
		// set timeInSWC blank
		setTimeInSWC("");
    }

	// creata url with search parameters
	function addParamsToUrl(endpoint, params) {
		// Create a URL object to handle query parameters
		let paramsname = "";
		params.forEach(param => {
			if (param.key && param.value) {
				paramsname = paramsname+`${param.key}=${param.value}&`;
			}
		});
		if(paramsname != ""){
			//remove / from the string
			endpoint = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
			endpoint = endpoint + "?" + paramsname;
		}
		setEndpointUrl(endpoint);
		return endpoint.toString();
	}
	//set
	//function to save data connection
	const handleSaveConnection = () => {
		setConloaderStatus( true );
		if (method == "get") {
			addParamsToUrl(endpointUrl, paramskeyValuePairs);
		}
		// Step 1: Form the payload
		const payload = {
			endpointName: authConfig.endpoint_name, // Replace with actual input for endpoint name
			endpointUrl: endpointUrl, // Assuming you have this from state
			authorization: {
			type: selectedAuthType, // e.g., "Bearer" or "Basic"
			token: headers.find((header) => header.key === "Authorization")?.value || "", // Token value from headers
			},
			headers: headers, // Dynamic headers added in the form
			body: {
			type: selectedBodyType, // Selected body type, e.g., "formdata", "raw"
			data: selectedBodyType === "formdata" ? keyValuePairs : rawBody, // Form data or raw body
			},
			mapping: mappings, // Response to column mappings
			method:method,
			authConfig:authConfig,
			selectedDatabase:selectedDatabase,
			timeInSWC:timeInSWC,
			params:paramskeyValuePairs,
			operation:operation
		};
		
		// Step 2: Validate inputs
		if (!payload.endpointName || !payload.endpointUrl) {
			setErrorMessageWithTimeout("Endpoint name and URL are required!");
			return;
		}
		
		if (selectedAuthType !== "none" && !payload.authorization.token) {
			setErrorMessageWithTimeout("Authorization token is required!");
			return;
		}
		
		if (selectedBodyType === "formdata" && keyValuePairs.some(pair => !pair.key || !pair.value)) {
			setErrorMessageWithTimeout("All form data key-value pairs must be filled!");
			return;
		}
		if(authConfig?.id == null){
			// Step 3: Prepare the JSON payload (already created above)
			dataconnection.addDataApiConnection(payload).then((res) => {
				let st = res?.status ?? "";
				if(st == 'success'){
					loadToolApiendpoint();
					resetCOnnectform();
					setErrorMessageWithTimeout(res?.message ?? "Data added succesfully.",'success');
				}else{
					setErrorMessageWithTimeout(res?.message ?? "All form data must be filled!");
				}
				setConloaderStatus( false );
			});
		}else{
			// Step 3: Prepare the JSON payload (already created above)
			dataconnection.editDataApiConnection(authConfig?.id,payload).then((res) => {
				loadToolApiendpoint();
                resetCOnnectform();
				setErrorMessageWithTimeout(res?.message ?? "Data modified succesfully.",'success');
				setConloaderStatus( false );
			});
		}
        
		
	};
	
    //reset forms
    const renderAuthFields = () => {
		return (
			<div style={{ minHeight: "160px" }}> {/* Adjusted height to accommodate the API Key fields */}
				{selectedAuthType === "none" && (
					<p>The authorization header will be automatically generated when you send the request.</p>
				)}
				{selectedAuthType === "basic" && (
					<>
						<Form.Field>
							<label>Username</label>
							<Input 
								placeholder="Enter Username" 
								value={authConfig.username} 
								onChange={handleUsernameChange} 
							/>
						</Form.Field>
						<Form.Field>
							<label>Password</label>
							<Input 
								placeholder="Enter Password" 
								type="password" 
								value={authConfig.password} 
								onChange={handlePasswordChange} 
							/>
						</Form.Field>
					</>
				)}
				{selectedAuthType === "bearer" && (
					<Form.Field>
						<label>Bearer Token</label>
						<Input 
							placeholder="Enter Bearer Token" 
							value={authConfig?.token} 
							onChange={handleTokenChange} 
						/>
					</Form.Field>
				)}
				{selectedAuthType === "apikey" && (
					<>
						<Form.Field>
							<label>Key</label>
							<Input
							placeholder="Enter API Key"
							value={authConfig.apiKey.key}
							onChange={handleKeyChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Value</label>
							<Input
							placeholder="Enter Value"
							value={authConfig.apiKey.value}
							onChange={handleValueChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Place</label>
							<Dropdown
							placeholder="Select Place"
							fluid
							selection
							options={[
								{ key: "header", value: "header", text: "Header" },
								{ key: "param", value: "param", text: "Param" },
							]}
							value={authConfig.apiKey.type}
							onChange={handlePlaceChange}
							/>
						</Form.Field>
					</>
				)}
			</div>
		);
	};
	
	const renderResponsePanel = () => (
        <div>
            {responseOutput ? (
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {JSON.stringify(responseOutput, null, 2)}
                </pre>
            ) : (
                <p>No response available. Click "Test" to fetch data.</p>
            )}
        </div>
    );

	const handleAddKeyValuePair = () => {
		setKeyValuePairs([...keyValuePairs, { key: "", value: "" }]);
	};

	// Remove Key-Value Pair
	const handleRemoveKeyValuePair = (index) => {
		setKeyValuePairs(keyValuePairs.filter((_, i) => i !== index));
	};

	// Update Key-Value Pair
	const handleKeyValueChange = (index, field, value) => {
		const updatedPairs = keyValuePairs.map((pair, i) =>
			i === index ? { ...pair, [field]: value } : pair
		);
		setKeyValuePairs(updatedPairs);
	};
	
	
	// Render Body Panel
	const renderBodyPanel = () => (
		<div>
			<Form>
				<Form.Field>
					<label>Body Type</label>
					<Form.Group>
						<Form.Radio
							label="Form Data"
							value="formdata"
							checked={selectedBodyType === "formdata"}
							onChange={(e, { value }) => setSelectedBodyType(value)}
						/>
						<Form.Radio
							label="x-www-form-urlencoded"
							value="x-www-form-urlencoded"
							checked={selectedBodyType === "x-www-form-urlencoded"}
							onChange={(e, { value }) => setSelectedBodyType(value)}
						/>
						<Form.Radio
							label="Raw"
							value="raw"
							checked={selectedBodyType === "raw"}
							onChange={(e, { value }) => setSelectedBodyType(value)}
						/>
					</Form.Group>
				</Form.Field>

				{selectedBodyType === "raw" && (
					<Form.Field>
						<label>Raw JSON</label>
						<textarea
							placeholder="Enter JSON"
							style={{ height: "150px", width: "100%", resize: "vertical" }}
							value={rawBody}
							onChange={(e) => {
								const inputValue = e.target.value;
								setRawBody(inputValue); // Update the state with the current input
								try {
									JSON.parse(inputValue); // Validate JSON
								} catch {
									console.error("Invalid JSON");
								}
						}}
						/>
					</Form.Field>
				)}

				{(selectedBodyType === "formdata" || selectedBodyType === "x-www-form-urlencoded") && (
					<Form.Field>
						<label>Key-Value Pairs</label>
						{renderKeyValuePairs()}
					</Form.Field>
				)}
			</Form>
		</div>
	);

	// Render Key-Value Pairs
	const renderKeyValuePairs = () => (
		<>
			{keyValuePairs.map((pair, index) => (
				<div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
					<Input
						placeholder="Key"
						value={pair.key}
						onChange={(e) => handleKeyValueChange(index, "key", e.target.value)}
						style={{ marginRight: "10px" }}
					/>
					<Input
						placeholder="Value"
						value={pair.value}
						onChange={(e) => handleKeyValueChange(index, "value", e.target.value)}
						style={{ marginRight: "10px" }}
					/>
					{keyValuePairs.length > 1 && (
						<Button
							icon="minus"
							color="red"
							onClick={() => handleRemoveKeyValuePair(index)}
							style={{ marginRight: "10px" }}
						/>
					)}
				</div>
			))}
			<Button icon="plus" color="green" onClick={handleAddKeyValuePair} />
		</>
	);

	// active responsetab
	const triggerResponseTabClick = () =>  {

	}

	//function which will hit the API according to configral setup
	const handleTestEndpoint = async () => {
		if (!endpointUrl) {
			setErrorMessageWithTimeout("Please enter a valid Endpoint URL.");
			return;
		}
		try {
			const requestHeaders = {};
			// Add Authorization Header
			if (selectedAuthType === "basic") {
				const authToken = btoa(`${authConfig.username}:${authConfig.password}`);
				requestHeaders["Authorization"] = `Basic ${authToken}`;
			} else if (selectedAuthType === "bearer") {
				requestHeaders["Authorization"] = `Bearer ${authConfig.token}`;
			} else if (selectedAuthType === "apikey" && authConfig.apiKey.place === "header") {
				requestHeaders[authConfig.apiKey.key] = authConfig.apiKey.value;
			}
	
			// Add Custom Headers
			headers.forEach(({ key, value }) => {
				if (key && value) {
					requestHeaders[key] = value;
				}
			});
	
			// Prepare Request Body
			let requestBody = null;
			if (method !== "get") {
				if (selectedBodyType === "formdata") {
					const formData = new FormData();
					keyValuePairs.forEach(({ key, value }) => {
						if (key && value) {
							formData.append(key, value);
						}
					});
					requestBody = formData;
				} else if (selectedBodyType === "x-www-form-urlencoded") {
					requestBody = new URLSearchParams(
						keyValuePairs.reduce((acc, { key, value }) => {
							if (key && value) {
								acc[key] = value;
							}
							return acc;
						}, {})
					).toString();
				} else if (selectedBodyType === "raw") {
					requestBody = JSON.parse(rawBody);
					requestHeaders["Content-Type"] = "application/json";
				}
			}else{
				setEndpointUrl(addParamsToUrl(endpointUrl, paramskeyValuePairs))
			}
			// API Request
			const response = await axios({
				url: endpointUrl,
				method,
				headers: requestHeaders,
				data: requestBody,
			});
	
			setResponseOutput(response.data);
			// Extract and set available keys
			const extractedKeys = extractKeysFromResponse(response.data);
			setAvailableKeys(extractedKeys);

			triggerResponseTabClick(); // Trigger the Responses tab click after successful API call
		} catch (error) {
			setResponseOutput(error.response || { error: error.message });
			triggerResponseTabClick(); // Trigger the Responses tab click
		}
	};
	
	//function which will hit the API according to configral setup

	// Render header management section
	const renderHeaderManagement = () => (
		<div>
			<h4>Manage Headers</h4>
			{headers.map((header, index) => (
				<div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
					<Input
						placeholder="Key"
						value={header.key}
						onChange={(e) => handleHeaderChange(index, "key", e.target.value)}
						style={{ marginRight: "10px", flex: 1 }}
					/>
					<Input
						placeholder="Value"
						value={header.value}
						onChange={(e) => handleHeaderChange(index, "value", e.target.value)}
						style={{ marginRight: "10px", flex: 1 }}
					/>
					<Button
						icon="minus"
						color="red"
						onClick={() => handleRemoveHeader(index)}
						style={{ marginRight: "10px", width: "80px", padding: "5px" }}
					/>
				</div>
			))}
			<Button icon="plus" color="green" onClick={handleAddHeader} style={{ width: "100px", padding: "5px" }} />
		</div>
	);
	
	// header management 

	// Add a new header row
	const handleAddHeader = (type = 'header') => {
		if(type=="params"){
			setParamKeyValuePairs([...paramskeyValuePairs, { key: "", value: "" }]);
		}else{
			setHeaders([...headers, { key: "", value: "" }]);
		}
	};

	// Remove a header row
	const handleRemoveHeader = (index,type="header") => {
		if(type=="params"){
			setParamKeyValuePairs(paramskeyValuePairs.filter((_, i) => i !== index));
		}else{
			setHeaders(headers.filter((_, i) => i !== index));
		}
	};

	// Update a specific header's key or value
	const handleHeaderChange = (index, field, value,type="header") => {
		if(type=="params"){
			const updatedHeaders = paramskeyValuePairs.map((header, i) =>
				i === index ? { ...header, [field]: value } : header
			);
			setParamKeyValuePairs(updatedHeaders);
		}else{
			const updatedHeaders = headers.map((header, i) =>
				i === index ? { ...header, [field]: value } : header
			);
			setHeaders(updatedHeaders);
			
		}
	};
    const panes = [
		{	
            menuItem: (
                <Menu.Item
                    key="params"
                    className={activeTab === "params" ? styles.menuActiveDataconnection : ""}
                    onClick={() => setActiveTab("params")}
                >
                    Params
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
						<div>
							<h4>Manage Params</h4>
							{paramskeyValuePairs.map((header, index) => (
								<div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
									<Input
										placeholder="Key"
										value={header.key}
										onChange={(e) => handleHeaderChange(index, "key", e.target.value,"params")}
										style={{ marginRight: "10px", flex: 1 }}
									/>
									<Input
										placeholder="Value"
										value={header.value}
										onChange={(e) => handleHeaderChange(index, "value", e.target.value,"params")}
										style={{ marginRight: "10px", flex: 1 }}
									/>
									<Button
										icon="minus"
										color="red"
										onClick={() => handleRemoveHeader(index,"params")}
										style={{ marginRight: "10px", width: "80px", padding: "5px" }}
									/>
								</div>
							))}
							<Button icon="plus" color="green" onClick={() => handleAddHeader("params")} style={{ width: "100px", padding: "5px" }} />
						</div>
                </Tab.Pane>
            ),
        },
        {	
            menuItem: (
                <Menu.Item
                    key="authorization"
                    className={activeTab === "authorization" ? styles.menuActiveDataconnection : ""}
                    onClick={() => setActiveTab("authorization")}
                >
                    Authorization
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Form>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {/* Left Section: Auth Type */}
                            <div style={{ width: "40%", borderRight: "1px solid rgb(190 202 213)", paddingRight: "10px" }}>
                                <Form.Field>
                                    <label>Auth Type</label>
                                    <Dropdown
                                        placeholder="Select Auth Type"
                                        fluid
                                        selection
                                        options={authOptions}
                                        onChange={handleAuthTypeChange}
                                        value={selectedAuthType}
                                    />
                                </Form.Field>
                            </div>

                            {/* Right Section: Dynamic Fields */}
                            <div style={{ width: "55%" }}>
                                {renderAuthFields()}
                                {/* Buttons below */}
                                
                            </div>
                        </div>
                    </Form>
                </Tab.Pane>
            ),
        },
        {
			menuItem: (
				<Menu.Item
					key="headers"
					onClick={() => setActiveTab("headers")}
					className={activeTab === "headers" ? styles.menuActiveDataconnection : ""}
				>
					Headers
				</Menu.Item>
			),
			render: () => <Tab.Pane>{renderHeaderManagement()}</Tab.Pane>,
		},
        {
            menuItem: (
				<Menu.Item
					key="body"
					className={activeTab === "body" ? styles.menuActiveDataconnection : ""}
					onClick={() => setActiveTab("body")}
				>
					Body
				</Menu.Item>
			),
			render: () => <Tab.Pane>{renderBodyPanel()}</Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item
                    key="responses"
                    onClick={() => setActiveTab("responses")}
                    className={activeTab === "responses" ? styles.menuActiveDataconnection : ""}
                >
                    Responses
                </Menu.Item>
            ),
			render: () => <Tab.Pane>{renderResponsePanel()}</Tab.Pane>,
        },
        {
			menuItem: (
				<Menu.Item
					key="settings"
					onClick={() => setActiveTab("settings")}
					className={activeTab === "settings" ? styles.menuActiveDataconnection : ""}
				>
					Settings
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane>
					{/* reser database selection */}
					{renderDatabaseSelection(selectedDatabase, handleDatabaseChange)}
					{/* Render Settings Items */}
					{renderSettingsItems(databaseColumns, availableKeys, handleMapping,mappings)}
				</Tab.Pane>
			),
		}
		
    ];

	const onEditApiEndpont = (apicon) => {
		
		let operation = apicon.operation ?? "";
		setOperation(operation);

		let endpoint_url = apicon.endpoint_url ?? "";
		setEndpointUrl(endpoint_url);

		let mapping = apicon.mapping ?? "";
		setMappings(mapping);
		let selectedDatabase = apicon.selectedDatabase ?? "";
		setSelectedDatabase(selectedDatabase);
		
		let method = apicon.method ?? "get";
		setMethod(method);
		let headers = apicon.headers ?? [];
		setHeaders(headers);
		let authorization_type = apicon.authorization_type ?? "none";
		if(authorization_type == 'Bearer Token'){
			authorization_type = 'bearer'
		}
		let params = apicon.params ?? [];
		setParamKeyValuePairs(params);

		setSelectedAuthType(authorization_type);
		  // Set body details
		if (apicon.body) {
			setSelectedBodyType(apicon.body.type || "formdata");
			if (apicon.body.type === "raw") {
				try {
					const parsedData = JSON.parse(apicon.body.data || "{}");
					setRawBody(JSON.stringify(parsedData, null, 2)); // Prettify JSON for editing
				} catch (error) {
					console.error("Invalid JSON in raw body data", error);
				}
			} else if (apicon.body.type === "formdata" || apicon.body.type === "x-www-form-urlencoded") {
				const keyValuePairs = Object.entries(JSON.parse(apicon.body.data || "{}")).map(
					([key, value]) => ({ key, value })
				);
				setKeyValuePairs(keyValuePairs);
			}
		}
		let authconn = apicon?.authConfig ?? authConfig;
		setAuthConfig(authconn)
		setAuthConfig((prev) => ({
			...prev,
			id:apicon.id,
            endpoint_name:apicon.endpoint_name
		}))
		// set values for time in swc from apicon
		setTimeInSWC(apicon?.timeInSWC ?? "");
		if (topRef.current) {
			topRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}
	
	
    return (
        <>
            <DataProvider>
				{
					showConnectionform && 
					<>
						{/* Display error message */}
						{errorMessage && (
							(errorMessageType == 'warning') ? <Message error >
								<Message.Header>Validation Error</Message.Header>
								<p>{errorMessage}</p>
							</Message> 
							: <Message success >
								<Message.Header>Succes message</Message.Header>
								<p>{errorMessage}</p>
							</Message>
						)}
						<div style={{ padding: "40px" }}>
							<Form>
								<Form.Field>
									<Grid columns={2} stackable>
										<label style={{ width: "30%" }}>Endpoint Name</label>
										<Input style={{ width: "69%" }} 
											placeholder="Endpoint name" 
											value={authConfig.endpoint_name}
											onChange={
												(e) => setAuthConfig({
												...authConfig,
												endpoint_name:e.target.value
											})
										}
										/>
									</Grid>
								</Form.Field>
								<Form.Field>
									<Grid columns={2} stackable style={{ marginTop: "2rem" }}>
										<label style={{ width: "30%" }}>Endpoint URL</label>
										<Input
											placeholder="https://www.example.com"
											value={endpointUrl}
											style={{ width: "69%" }}
											action={{
												content: "Test",
												color:"blue",
												className:"btn linear-gradient",
												style: { width: "15%" },
												onClick : handleTestEndpoint,
											}}
											onChange={(e) => setEndpointUrl(e.target.value)}
											label={
												<Dropdown
													placeholder="GET"
													options={[
														{ key: "get", text: "GET", value: "get" },
														{ key: "post", text: "POST", value: "post" },
														{ key: "put", text: "PUT", value: "put" },
														{ key: "delete", text: "DELETE", value: "delete" },
														{ key: "patch", text: "PATCH", value: "patch" },
													]}
													value={method}
													onChange={(e, data) => {
														const selectedValue = data.value; // Capture the value from the event data
														setMethod(selectedValue);
													}}
													selection
													style={{ minWidth: "80px", width: "15%" }}
												/>
											}
											labelPosition="left"
										/>
									</Grid>
								</Form.Field>
							</Form>
							<Tab  panes={panes} style={{ marginTop: "2rem" }} />
						</div>
					</>
				}
                {modalStatus && (
                    <DataLayerModal
                        open={modalStatus}
                        onClose={() => setmodalStatus(false)}
                        onOpen={() => setmodalStatus(true)}
                        setDatabases={setDatabases}
                        initFormdata={{...initFormdata}}
						loading={dbloaderSatus}
						setLoading={setDbloaderStatusp}
                    />
                )}
				<div style={{ display: "flex", justifyContent: "right", alignItems: "center", marginTop: "1rem" }}>
					
					<Button
						color="green"
						className={`btn linear-gradient yr-submitbtn btn-sm ${styles.button}`}
						onClick={() => {
						// Define the save new connection logic here
						handleSaveConnection(); // Assuming you have this function
						}}
					>
						Save New Connection
					</Button>
				</div>

				<div style={{ marginTop: "2rem" }}>
					{/* Other components like the form and tabs */}
					
					<button className={`${styles.linkButton}`	} > 
						Data Layer Listing
					</button>
					<ConnectionList
						onEdit={onEditApiEndpont}
						toolapiendpoint={toolapiendpoint}
						loadToolApiendpoint={loadToolApiendpoint}
						conloaderSatus = {conloaderSatus}
						setConloaderStatus = {setConloaderStatus}
					/>
				</div>
				<div style={{ marginTop: "2rem" }} className={`${styles.datalayerTable}`}>
					<a onClick={() => {
							setmodalStatus(!modalStatus);
						}}>
						<button className={`${styles.linkButton}`	} > 
							<i className="fas fa-plus"></i>
							Add new data connection
						</button>
					</a>
					
					<DatabaseListing
						databases={databases}
						open={modalStatus}
						onClose={() => setmodalStatus(false)}
						onOpen={() => setmodalStatus(true)}
						setDatabases={setDatabases}
						setinitFormdata={setinitFormdata}
						loading={dbloaderSatus}
						setLoading={setDbloaderStatus}
					/>
				</div>
            </DataProvider>
        </>
    );
};

export default Dataconnectionwrapper;
