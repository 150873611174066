import React, { useContext, useState,useEffect } from 'react';
import { Table, Button, Icon, Modal } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import ConfirmationModal from './ConfirmationModal'; // Import the styled confirmation modal
import dataconnection from '../../../api/DataConnection/dataconnection';

const ConnectionList = ({ onEdit, toolapiendpoint ,loadToolApiendpoint,conloaderSatus,setConloaderStatus }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDb, setSelectedDb] = useState(null);

    const confirmDelete = () => {
        if (selectedDb) {
            setConloaderStatus(true)
            dataconnection.deleteDataApiConnection(selectedDb.id).then(() => {
                loadToolApiendpoint();
                closeModal();
            });
        }
    };

    useEffect(() => {
        if (toolapiendpoint != undefined) {
            setConloaderStatus(false);
        }
    }, [toolapiendpoint]);

    const openModal = (db) => {
        setSelectedDb(db);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedDb(null);
    };
    return (
        <>
        {
            (toolapiendpoint !== undefined && conloaderSatus == false) ?
            <div style={{ marginTop: "20px" }}>
                {toolapiendpoint.length === 0 ? (
                    <p>No connections available. Please add a new database.</p>
                ) : (
                    <div className={`${styles.datalayerTable}`}>
                        <Table >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>My Endpoint</Table.HeaderCell>
                                    <Table.HeaderCell>Connected Database</Table.HeaderCell>
                                    <Table.HeaderCell>Updated interval</Table.HeaderCell>
                                    <Table.HeaderCell>Add or Replace ?</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {toolapiendpoint.map((db) => (
                                    <Table.Row key={db.id}>
                                        <td className={`${styles.datalaytd}`}>{db.endpoint_url}</td>
                                        <td className={`${styles.datalaytd}`}>{db.selectedDatabase}</td>
                                        <td className={`${styles.datalaytd}`}>{db.timeInSWC}</td>
                                        <td className={`${styles.datalaytd}`}>{db.operation}</td>
                                        <td className={`${styles.datalaytd}`}>
                                            <div className={styles.actionButtons}>
                                                <span>
                                                    <i
                                                    className="la la-trash"
                                                    title="Delete Connection"
                                                    onClick={() => openModal(db)}
                                                    style={{ cursor: "pointer",marginRight: "10px" }}
                                                    ></i>
                                                </span>
                                                <span 
                                                    title="Edit Connection"
                                                    onClick={() => onEdit(db)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                <i className="la la-edit blue"></i>
                                                </span>
                                            </div>
                                            
                                        </td>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                )}

                {/* Custom Confirmation Modal */}
                
                    <ConfirmationModal
                        open={modalOpen}
                        onClose={closeModal}
                        onConfirm={confirmDelete}
                        
                    />
            </div> :  <p>Loading...</p>
        }
        </>
    );
};

export default ConnectionList;
