import React from 'react';
import { Table, Segment, Divider,Loader } from "semantic-ui-react";
import DataColumns from './DataColoums';
import DataRow from './DataRows';
import Filter from './Filter';
import config from '../../../config';
import util from "../../../util";
import Paginate from './Paginate';
import billing from "../../../api/billing";
import './DataTable.css'
import { CSVLink, CSVDownload } from "react-csv";

class Datatable extends React.Component{
    constructor( props ){
        super( props );
        this.state = {
            error:"",
            record:{},
            total:0,
            total_min:0,
            // dataLists: [],
            // totalPages: null,
            // totalRecord: null,
            // currentPage: 1,
            loading: true,
            // smsTotalAmount:0,
            // emailTotalAmount:0,
            // postcardTotalAmount:0,
            // cost_per_email:0,
            // cost_per_sms:0,
            // cost_per_postcard:0,
            // count_email:0,
            // count_sms:0,
            // count_postcard:0,
            // csvdata:[],
            // params: {
            //     _limit    : config.dataTableSettings.pageLimit,
            //     _order    : null,
            //     _sort     : null,
            //     _page     : 1,
            //     timeZone  : util.getTimeZone(),
            //     _query    : "all_time",
            //     _queryType: "all",
            //     dateTo    : null,
            //     dateFrom  : null
           // },
          };
          //this.csvLink = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    // changeParams = (data,feild) => {
    //     let params;
    //     if(feild =='_page' || feild == '_limit'){
    //         params = {...this.state.params,[feild]:data};
    //     }else{
    //         params = {...this.state.params,[feild]:data,dateTo:"",dateFrom:""};
    //     }
    //     this.setState({
    //         ...this.state,
    //         params:params
    //     },() => this.loadData() )
    // }

    // changeDateRangeParams = ( dateFrom,dateTo  ) => {
    //     let params = {...this.state.params,dateTo:dateTo,dateFrom:dateFrom};
    //     this.setState({
    //         ...this.state,
    //         params:params
    //     },() => this.loadData() )
    // }

    // getCsv = () => {
    //     this.setState({ loading: true });
    //     let data = {...this.state.params,userId : this.props.userid};
    //     billing.ExportBillingCsv( data ).then( ( resp ) => {
    //         if( typeof resp == "object" ){
    //             if(resp.status ){
    //                // alert("pass");
    //             this.setState({csvdata:resp.data}, () => {
    //                 setTimeout(() => {
    //                   this.setState({ loading: false });
    //                   document.getElementById("chatcsvqq").click();
    //                 }, 2000);
    //               });
                
                     
                   
    //             }else{
    //                 //alert("failure");
    //             }
    //         }
    //     });
    // }

    // sortElement = ( sort,order) => {
    //     let params = {...this.state.params,_sort:sort,_order:order};
    //     this.setState({
    //         ...this.state,
    //         params:params
    //     },() => this.loadData() )
    // }

    loadData = ( dataParams = null ) => {
        let response = null;
        this.setState({ loading: true });
        let data = {...this.state.params,userId : this.props.userid};
        // billing.GetMonthlyRengaged( data )
        // .then(
        //     ( resp ) => {
        //         if( typeof resp == "object" ){
        //             if( resp.status ){
        //                 this.setState({
        //                     ...this.state,
        //                     error : "",
        //                     totalRecord :   resp.count,
        //                     dataLists   :   resp.data,
        //                     currentPage :   resp.page,
        //                     totalPages  :   Math.ceil(resp.count/resp.per_page),
        //                     params      :   {...this.state.params,_limit:resp.per_page,_sort:resp.sort,_order:resp.order},
        //                     loading     :   false,
        //                     error       :   "",
        //                     smsTotalAmount  :   resp.groupByTotaData.sms,
        //                     emailTotalAmount  :   resp.groupByTotaData.email,
        //                     postcardTotalAmount  :   resp.groupByTotaData.postcard,
        //                     cost_per_email      :   resp.groupByTotaData.cost_per_email,
        //                     cost_per_sms        :  resp.groupByTotaData.cost_per_sms,
        //                     cost_per_postcard   :  resp.groupByTotaData.cost_per_postcard,
        //                     count_email         :  resp.groupByTotaData.email_count,
        //                     count_sms           :  resp.groupByTotaData.sms_count,
        //                     count_postcard      :  resp.groupByTotaData.postcard_count
        //                 });
        //             }else{
        //                 this.setState({
        //                     ...this.state,
        //                     loading             :   false,
        //                     error               : "No Data Found",
        //                     smsTotalAmount      :   0,
        //                     emailTotalAmount    :   0,
        //                     postcardTotalAmount :   0,
        //                     cost_per_email      :   0,
        //                     cost_per_sms        :  0,
        //                     cost_per_postcard   :  0,
        //                     count_email         :  0,
        //                     count_sms           :  0,
        //                     count_postcard      :  0,
        //                     params              : {...this.state.params,_limit:10,_sort:null,_order:null,_page:1},
        //                 })
        //             }
        //         }else{
        //             this.setState({
        //                 ...this.state,
        //                 loading     :   false,
        //                 error: "Something wents wrong Please try again ...."
        //             })
        //         }
        //     }
        // );
        billing.GetMonthlyvoiceUsage( data )
        .then(
            ( resp ) => {
              if(resp.status){
                    this.setState(
                        {
                            record: resp?.data ?? resp.data,
                            total: resp?.total ?? 0,
                            loading: false,
                            error: "",
                            total_min: resp?.total_mins ?? 0,
                           
                        }
                    )
              }else{
                this.setState(
                    {
                        record: {mins_in_plan:0},
                        total:  0,
                        loading: false,
                        error: "",
                        total_min:  0,
                       
                    }
                )
              }
            }
        );
    }

    render(){
        // let {dataColumns} = this.props;
        // let startPoint = ((this.state.currentPage -1 ) * this.state.params._limit ) + 1;
        if(this.state.error != "") {}
        return(
            <React.Fragment>
                {/* <Filter 
                    query = {this.state.params._query}
                    query_type = {this.state.params._queryType}
                    changeFilter = {this.changeParams}
                    changeDateRangeParams = {this.changeDateRangeParams}
                    getCsv = {this.getCsv}
                /> */}
                <div className="d-flex justify-content-between detail-box">
                
                    <div className="d-flex border rounded">
                        <span>Voice</span>
                        <ul>
                            <li>
                                <h4>Total minutes</h4>
                                <div>{this.state?.total_min ? this.state?.total_min : 0 }</div>
                            </li>
                            <li>
                                <h4>Minutes included in plan</h4>
                                <div>{ this.state?.record?.mins_in_plan ?? 0  }</div>
                            </li>
                            <li>
                                <h4>Usage cost</h4>
                                <div>{this.state?.total ? this.state?.total : 0 }</div>
                            </li>
                        </ul>
                    </div>
                  
                </div>
               

                { /*!this.state.error ? <Table customTable celled selectable sortable>
                    <DataColumns
                        dataColumns={dataColumns}
                        sortElement = {this.sortElement}
                    />
                    {Object.keys(this.state.dataLists).length != 0 && (
                        <DataRow
                            columnLength={this.state.dataLists.length}
                            dataRow={this.state.dataLists}
                            invoiceDetail = {this.props.invoiceDetail}
                            startPoint = {startPoint}
                        />
                    ) */}
                    {/* pagination */}
                    {/*
                        this.state.totalRecord > 0 ? (
                            <Paginate
                                totalPages={this.state.totalPages}
                                changePage={this.changeParams}
                                currentPage={this.state.currentPage}
                                totalRecord={this.state.totalRecord}
                                pageLimit={this.state.params._limit}
                                changePageSize={this.changeParams}
                                columnLength={dataColumns.length}
                                startPoint = {startPoint}
                            />
                        ) : (
                            <Paginate 
                                columnLength={this.state.dataLists.length}
                                changePage={this.changeParams}
                            />
                        ) }
                    </Table> : 
                    <React.Fragment>
                        <Table customTable celled selectable sortable>
                        <DataColumns
                            dataColumns={dataColumns}
                            sortElement = {this.sortElement}
                        />
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    <div style={{textAlign: 'center', padding: 10}}>{this.state.error}.</div>
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </React.Fragment>
                    */ }
         {this.state.loading && <Loader style={{ display: "block",top:"72%" }} />}       
        {/* <CSVLink
          id="chatcsvqq"
          data={this.state.csvdata}
          filename="monthly-usage.csv"
          className="block"
          target="_blank"
        />     */}
            </React.Fragment>
        )
    }
}
export default Datatable;