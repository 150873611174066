import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import dataconnection from '../../../api/DataConnection/dataconnection';
import { DataContext } from './DataProvider';
import DataLayerModal from './DataLayerModal';
import './CustomModal.css'; // Import custom styles for the modal
import ConfirmationModal from './ConfirmationModal'; // Import the styled confirmation modal

const DatabaseListing = (props) => {
  const { connections, updateConeection } = useContext(DataContext);
  const [conectlist, setConectlist] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(null);

  useEffect(() => {
      if (connections != undefined) {
        setConectlist(connections);
        props.setLoading(false);
      }
  }, [connections]);

  // Open confirmation modal
  const openModal = (database) => {
    setSelectedDatabase(database);
    setModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setSelectedDatabase(null);
    setModalOpen(false);
  };

  // Confirm delete action
  const confirmDelete = async () => {
    if (selectedDatabase) {
      try {
        props.setLoading(true);
        // Call API to delete the database
        dataconnection.deleteConnection(selectedDatabase.id).then((res) => {
          setConectlist(res.data);
          props.setLoading(false);
        });
      } catch (error) {
        console.error("Failed to delete the database:", error);
      } finally {
        closeModal();
      }
    }
  };

  // Open modal to update database
  const updateDBConeection = (database) => {
    props.setinitFormdata(database);
    props.onOpen();
  };

  const changejsontostring = (data) => {
    const parsedData = JSON.parse(data);
    const formattedString = parsedData
      .map((item) => `Name:${item.name}, Type:${item.type}`)
      .join(' | ');
    return formattedString;
  };
  return (
    <>
      {
        props.loading == true ? <p>Loading ...</p> :
         (conectlist != undefined && conectlist.length) ?
          <Table >
            <Table.Header>
              <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Columns</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
  
            <Table.Body>
              {conectlist != undefined &&
                conectlist.map((database) => (
                  <Table.Row key={database.id ?? ""}>
                    <td className={`${styles.datalaytd}`}>{database.name}</td>
                    <td className={`${styles.datalaytd}`}>{changejsontostring(database.columns) ?? ""}</td>
                    <td className={`${styles.datalaytd}`}>
                      <div className={styles.actionButtons}>
                        <span>
                          <i
                            className="la la-trash"
                            onClick={() => openModal(database)}
                            style={{ cursor: "pointer",marginRight: "10px" }}
                          ></i>
                        </span>
                        <a
                        style={{ cursor: "pointer" }}
                          onClick={() =>
                            updateDBConeection({
                              tablename: database.tablename,
                              coloumns: JSON.parse(database.columns),
                              id:database.id
                            })
                          }
                        >
                          <span>
                            <i className="la la-edit blue"></i>
                          </span>
                        </a>
                      </div>
                    </td>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table> :
          <p>No database available. Please add a new database.</p>
        
      }
      

      {/* Custom Confirmation Modal */}
      <ConfirmationModal
        open={modalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
      />

      {/* Edit Modal */}
      {props.modalStatus && (
        <DataLayerModal
          open={props.modalStatus}
          onClose={props.onClose}
          onOpen={props.onOpen}
          setDatabases={props.setDatabases}
          initFormdata={props.initFormdata}
        />
      )}
    </>
  );
};

export default DatabaseListing;
