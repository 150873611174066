import React, { useState, useContext } from "react";
import { DataProvider } from './DataProvider';
import Dataconnectionwrapper from './Dataconnectionwrapper'

const DataConnect = () => {
  
  return (
	<DataProvider>
		<Dataconnectionwrapper />
	</DataProvider>
  );
};

export default DataConnect;
